import { render, staticRenderFns } from "./modalEliminarEmail.vue?vue&type=template&id=08b6aae0&scoped=true&"
import script from "./modalEliminarEmail.vue?vue&type=script&lang=js&"
export * from "./modalEliminarEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b6aae0",
  null
  
)

export default component.exports