<template>
    <section>
        <titulo-divisor estilo="color: var(--color-general)" titulo="Correos que recibirán las notificaciones cuando un proveedor cotiza materiales">
            <el-tooltip content="Añadir correo" placement="bottom" effect="light" visible-arrow>
                <div class="wh-32 rounded-circle bg-5d d-middle-center icon-option-hover cr-pointer" @click="agregarCorreo">
                    <i class="icon-plus text-white f-20" />
                </div>
            </el-tooltip>
        </titulo-divisor>
        <div v-for="value in emailProveedores" :key="value.id" class="mb-3 border br-5 mx-3">
            <div class="border d-middle justify-content-between px-2" style="background: #F8F8F8">
                <p class="f-14 f-600"> {{ value.correo}} </p>
                <div class="d-middle-center icon-option">
                    <i class="icon-pencil f-20"  @click="updateEmail(value)"/>
                    <i class="icon-trash-can-outline f-20"  @click="removeEmail(value)"/>
                </div>
            </div>
            <div class="d-middle flex-wrap gap-1 m-3 ">
                <div v-for="item in value.proveedores_correos" :key="item.id" class="border br-6 pl-2 d-middle justify-content-between ">
                    <p>{{ item.nombre }}</p>
                    <i class="icon-close-circle px-2 f-15 cr-pointer" @click="eliminarProveedor(item, value.id)"/>
                </div>
            </div>
        </div>
        <modal-select-proveedores ref="openModalListarProveedores" />
        <modal-eliminar-email ref="openModalEliminarEmail" @eliminar="eliminarEmailNotificacion" />
        <modal-editar-select-proveedores ref="openModalEditarListarProveedores" />
        <modal-eliminar-proveedor ref="openModalEliminarProveedor" @eliminar="eliminarProveedorEmail" />


    </section>
</template>

<script>
import modalSelectProveedores from './partials/modalSelectProveedores'
import modalEditarSelectProveedores from './partials/modalEditarSelectProveedores'
import modalEliminarEmail from './partials/modalEliminarEmail'
import modalEliminarProveedor from './partials/modalEliminarProveedor'
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        modalSelectProveedores,
        modalEliminarEmail,
        modalEditarSelectProveedores,
        modalEliminarProveedor,
    },
    
    data(){
        return{
            toDelete: {},
            idEmail: '',
            idCorreo: ''
        }
    },
    computed: {
        ...mapGetters({
            emailProveedores: 'configurar/proveedores/proveedores/emailProveedores',
        }),
    },
    async created(){
        await this.Action_get_notificacion_email_pvs();
    },
    methods:{
        ...mapActions({
            Action_get_notificacion_email_pvs: 'configurar/proveedores/proveedores/Action_get_notificacion_email_pvs',
            Action_delete_email: 'configurar/proveedores/proveedores/Action_delete_email',
            Action_delete_proveedor: 'configurar/proveedores/proveedores/Action_delete_proveedor',
        }),
        agregarCorreo(){
            this.$refs.openModalListarProveedores.toggle()
        }, 
        async removeEmail(value){
            this.idEmail = value.id
            this.$refs.openModalEliminarEmail.toggle()

        },
        async eliminarEmailNotificacion(){
            await this.Action_delete_email(this.idEmail)
            this.idEmail = ''
            await this.Action_get_notificacion_email_pvs();
        },
        updateEmail(value){
            this.$refs.openModalEditarListarProveedores.toggle(value)

        },
        eliminarProveedor(item, id){
            this.idCorreo = item.id
            this.idEmail = id
            this.$refs.openModalEliminarProveedor.toggle()
        },
        async eliminarProveedorEmail(){
            const payload = {
                id:this.idEmail,
                id_email:this.idCorreo
            }
            await this.Action_delete_proveedor(payload)
            this.idCorreo = ''
            this.idEmail = ''
            await this.Action_get_notificacion_email_pvs();
        }  

    },
}

</script>

<style>

</style>