<template>
    <modal ref="listarProveedores" titulo="Editar correo" adicional="Guardar" @adicional="guardarNotificacion">
        <div class="m-3">
            <ValidationObserver ref="validator">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider v-slot="{ errors }" name="correo" rules="required|email">
                            <p class="input-label-top">Correo</p>
                            <el-input v-model="correo" size="small" clear="w-100" placeholder="Correo" />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3">
                        <p class="input-label-top">Seleccionar materiales</p>
                        <ValidationProvider v-slot="{ errors }" name="proveedor" rules="required">
                            <el-select v-model="proveedores" :disabled="selectAll" placeholder="Seleccionar materiales" filterable multiple size="small" class="w-100">
                                <el-option v-for="item in select_providers" :key="item.id" :label="item.proveedor" :value="item.id"/>
                            </el-select>
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3">
                        <el-checkbox v-model="selectAll" @change="seleccionar" >Seleccionar todos</el-checkbox>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            proveedores: [], 
            correo: '',
            selectAll: false,
            idNotificacionPvs: ''       

        }
    },
    computed: {
        ...mapGetters({
            select_providers: "selects/selects/select_providers"
        }),
    },
    async created(){
        await this.Action_get_select_providers();
    },
    methods: {
        ...mapActions({
            Action_get_select_providers: 'selects/selects/Action_get_select_providers',
            Action_put_notificacion_email_pvs: 'configurar/proveedores/proveedores/Action_put_notificacion_email_pvs',

        }),
        toggle(value){
            this.idNotificacionPvs = value.id
            this.correo = value?.correo ?? ''
            this.proveedores = value?.proveedores_correos ? value.proveedores_correos.map(e => e.id_proveedor) : []
            this.selectAll = false 
            this.$refs.listarProveedores.toggle()
        },
        async guardarNotificacion(){
            const validate = await this.$refs.validator.validate()
            if (!validate) return  
            const data = {
                email: this.correo,
                id_proveedores: this.proveedores
            }
            const payload = {
                id:this.idNotificacionPvs,
                data: data
            }
            await this.Action_put_notificacion_email_pvs(payload)
            this.limpiar()
            this.$refs.listarProveedores.toggle()
        },
        seleccionar(value){
            if (value){
                //selecciona todo
                this.proveedores = this.select_providers.map(el => el.id)
            }else{
                //deselecciona
                this.proveedores = []
            }
        },
        limpiar(){
            this.proveedores =  []
            this.correo = ''
            this.selectAll = false
            this.idNotificacionPvs = ''
        }
    }
}
</script>

<style lang="css" scoped>
</style>
