<template>
    <modal ref="eliminarEmail" titulo="Eliminar Correo" adicional="Eliminar" @adicional="eliminarEmailNotificacion">
        <div class="m-3">
           <div class="row">
               <p class="col-12 f-14 text-center">¿Está seguro que desea eliminar este correo?</p>
           </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.eliminarEmail.toggle()
        },
        eliminarEmailNotificacion(){
            this.$refs.eliminarEmail.toggle()
            this.$emit('eliminar')  
        }
    }
}
</script>

<style lang="css" scoped>
</style>
