<template>
    <section>
        <navbar-admin titulo="configuración proveedores" icon="icon-cog">

            <div class="wh-42 bg-5d br-2 d-middle-center">
                <i class="icon-hand-extended text-white f-25" />
            </div>
        </navbar-admin>
        <correos-proveedores/>
    </section>
</template>

<script>
import  correosProveedores  from "./correosProveedores";
export default {
    components:{
        correosProveedores
    }
}
</script>

<style>

</style>